<template>
<div>
  <div class="full-width category-container">
    <div class="container">
      <div class="col-12 category-filters question-answer">
        <h1>Domande Frequenti</h1>

        <p>In questa sezione troverai le nostre risposte alle tue domande più frequenti su ordini, prodotti, spedizioni, acquisti e consegne, sulla registrazione al sito e su tutti i servizi che Altromercato ti offre.<br>Se necessiti di ulteriore assistenza, ricordati che puoi sempre contattare il nostro Servizio Clienti compilando il <a href="https://www.altromercato.it/contatti/">modulo di contatto</a> o scrivendoci a <a href="mailto:ecommerce@altromercato.it">ecommerce@altromercato.it</a> per domande relative ai tuoi acquisti online.</p>

        <div v-if="!loading && !error">
          <div v-for="(item, k) in faqs" v-bind:key="k">
            <h3>{{item.title}}</h3>
            <ul>
              <li v-for="(faq, l) in item.answers" v-bind:key="l">
                <div class="question-answer">
                  <h4><span v-html="faq.question"></span></h4>
                  <p><span v-html="faq.answer"></span></p>
                  <br>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <center v-if="!loading && error">
          <h3>Qualcosa è andato storto con il recupero delle faq, riprova più tardi</h3>
        </center>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Faq',

  metaInfo: {
    title: 'FAQ'
  },

  data: function () {
    return {
      loading: true,
      error: false,
      faqs: []
    }
  },

  async mounted () {
    await this.getFaq()
  },

  methods: {
    async getFaq () {
      try {
        let resp = await axios.post(this.$config.cmsUrl + 'index.php?action=get_faqs', "{}")
        if (resp.data.status != 'OK') {
          this.error = true
          this.loading = false
        } else {
          this.faqs = JSON.parse(resp.data.data)
          console.log(this.faqs)
          this.complete()
        }

      } catch (e) {
        this.$toast.open('Qualcosa è andato storto')
      }
    },
    async complete () {
      this.loading = false
    }
  }
}
</script>

<style scoped>
.category-container {
  margin-top: 38px;
  padding-bottom: 60px;
  letter-spacing: 0.3px
}

.question-answer > p {
  line-height: 150%;
}

.category-content h2 {
  font-size: 38px;
  font-weight: 600;
  letter-spacing: 0.4px;
  margin: 0 0 20px 0;
}

.category-content p {
  margin: 0 0 25px 0;
  max-width: 66.66666%;
  line-height: 140%;
  font-size: 14px;
  font-weight: 500;
}

.single-filter {
  width: 100%;
  padding-right: 50px;
}

.single-filter .filter-name {
  display: block;
  font-weight: 800;
  font-size: 13px;
  margin-bottom: 20px;
  text-transform: uppercase;
  position: relative;
  height: 25px;
  line-height: 25px;
  margin-top: 0;
  cursor: pointer;
  letter-spacing: 0.5px;
}

.single-filter .filter-name:after {
  content: '';
  position: absolute;
  top: -1px;
  right: 0;
}

.single-filter .filter-content {
  padding-left: 40px;
  margin-bottom: 40px;
}

.single-filter .filter-content a {
  display: block;
  font-weight: 500;
  font-weight: 13px;
  margin-bottom: 12px;
}
</style>
